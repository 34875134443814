<template>
  <div class="container container_feedbackM">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">意见反馈</div>
    </div>
    <div class="pageTitle">意见类型（必填）</div>
    <div class="typeWrap">
      <div
        class="typeBtn"
        :class="{ typeBtnActive: item.id == currentIndex }"
        v-for="item in type"
        :key="item.id"
        @click="currentIndex = item.id"
      >
        {{ item.content }}
      </div>
    </div>
    <div class="inputWrap">
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        v-model="text"
        placeholder="请描述问题"
        prop="desc"
      ></textarea>
    </div>
    <div class="pageTitle">上传图片</div>
    <div class="imgWrap">
      <el-upload
        action="https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :limit="3"
        :on-success="handleSuccess()"
        :auto-upload="false"
        ref="upload"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <div class="submitBtn" @click="submitUpload">提交</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      currentIndex: 0,
      type: [
        { id: 0, content: '文章问题' },
        { id: 1, content: '系统问题' },
        { id: 2, content: '举报投诉' },
        { id: 3, content: '视频问题' },
        { id: 4, content: '功能与意见' },
        { id: 5, content: '其他' }
      ],
      text: '',
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      let bottom = document.getElementsByClassName('bottom')[0]
      bottom.style.display = 'none'
    }
  },
  methods: {
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
      console.log(this.dialogImageUrl)
    },
    handleSuccess(response, file, fileList) {
      console.log(response)
      console.log(file)
      console.log(fileList)
    },
    submitUpload() {
      this.$refs.upload.submit()
    }
  }
}
</script>

<style lang="less">
.navBar_m {
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .leftIcon {
    width: 40px;
    height: 100%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    position: relative;
    z-index: 6;
  }
  .navBarText_m {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    font-family: SourceHanSansSC-Regular;
    font-size: 18px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.container_feedbackM {
  padding: 0 0.3rem;
  .pageTitle {
    width: 100%;
    height: 0.32rem;
    font-family: SourceHanSansCN-Regular;
    font-size: 0.32rem;
    color: #010101;
    margin-bottom: 0.38rem;
  }
  .typeWrap {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .typeBtn {
      width: 2rem;
      height: 0.54rem;
      border-radius: 0.27rem;
      background-color: #82858c;
      font-family: SourceHanSansCN-Normal;
      font-size: 0.28rem;
      line-height: 0.54rem;
      color: #ffffff;
      text-align: center;
      margin-bottom: 0.28rem;
    }
    .typeBtnActive {
      width: 2rem;
      height: 0.54rem;
      background-color: #2e333f;
      border-radius: 0.27rem;
    }
  }
  .inputWrap {
    width: 100%;
    height: 3.2rem;
    background-color: #eeeeee;
    border: solid 0.02rem #d8d8d8;
    margin-bottom: 0.92rem;
    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      background: none;
      border: none;
      padding: 0.1rem;
      outline: none;
      text-align: justify;
      font-size: 0.14rem;
      line-height: 16px;
    }
  }
}
.el-upload--picture-card {
  width: 88px;
  height: 88px;
  line-height: 90px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 88px;
  height: 88px;
}
.submitBtn {
  width: 100%;
  height: 0.97rem;
  background-color: #ffb11a;
  font-family: SourceHanSansCN-Normal;
  font-size: 0.36rem;
  line-height: 0.97rem;
  text-align: center;
  color: #ffffff;
  margin-top: 1.25rem;
}
</style>
