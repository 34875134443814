<template>
  <div class="container feedbackListM">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">意见反馈</div>
    </div>
    <router-link
      class="list_m"
      to="/Result"
      v-for="item in listData"
      :key="item.id"
    >
      <div class="listTitle">反馈答复</div>
      <div class="time">{{ item.time }}</div>
      <div class="content">{{ item.content }}</div>
      <div class="bottomLine"></div>
      <div class="itemBottom">
        <div class="itemLeft">查看详情</div>
        <div class="itemRight iconfont icon-jiantou"></div>
      </div>
    </router-link>
    <div class="empty" v-if="listData.length == 0">
      <img src="@/assets/images/empty.png" alt="" />
    </div>
    <el-pagination
      v-if="listData.length != 0"
      :hide-on-single-page="single"
      small
      layout="prev, pager, next"
      :page-size="10"
      :total="20"
    ></el-pagination>
  </div>
</template>
icon-ai-message
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      single: true,
      listData: [
        {
          id: 0,
          time: '2020-8-5  20:44',
          content:
            '我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈...'
        },
        {
          id: 1,
          time: '2020-8-5  20:44',
          content:
            '我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈...'
        },
        {
          id: 2,
          time: '2020-8-5  20:44',
          content:
            '我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈...'
        },
        {
          id: 3,
          time: '2020-8-5  20:44',
          content:
            '我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈...'
        },
        {
          id: 4,
          time: '2020-8-5  20:44',
          content:
            '我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈内容我的反馈...'
        }
      ]
    }
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      let bottom = document.getElementsByClassName('bottom')[0]
      bottom.style.display = 'none'
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  methods: {
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="less">
.feedbackListM {
  width: 100%;
  padding: 0 0.27rem;
  .list_m {
    display: block;
    width: 100%;
    // height: 2.88rem;
    padding: 0.26rem 0.22rem;
    background-color: #ffffff;
    border-radius: 0.1rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    .listTitle {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 0.4rem;
      font-family: SourceHanSansCN-Normal;
      font-size: 0.3rem;
      color: #424242;
    }
    .time {
      width: 100%;
      font-family: SourceHanSansCN-Normal;
      font-size: 0.24rem;
      color: #999999;
    }
    .content {
      width: 100%;
      height: 42px;
      font-family: SourceHanSansCN-Regular;
      font-size: 0.24rem;
      line-height: 0.29rem;
      color: #666666;
      text-align: justify;
    }
    .bottomLine {
      width: 100%;
      height: 1px;
      background-color: #eff0f4;
      margin: 0.33rem 0 0.14rem 0;
    }
    .itemBottom {
      width: 100%;
      height: 23px;
      font-family: SourceHanSansCN-Regular;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .itemLeft {
        font-family: SourceHanSansCN-Regular;
        font-size: 0.24rem;
        color: #999999;
      }
    }
  }
}
.navBar_m {
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .leftIcon {
    width: 40px;
    height: 100%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    position: relative;
    z-index: 6;
  }
  .navBarText_m {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    font-family: SourceHanSansSC-Regular;
    font-size: 18px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.empty {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  img {
    display: block;
    width: 100%;
  }
}
.bottom {
  display: none;
}
body {
  background: #eff0f4;
}
.feedbackListM {
  padding-bottom: 60px;
}
</style>
